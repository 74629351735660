<template>
    <div>
        <div class="loading">
            <div class="loading-title">
                <span>
                    审核详情
                </span>
            </div>
            <div style="display: flex;padding: 0 20px">
                <img style="width: 86px;height: 86px;" :src="preFix+ userList.goods.img" alt="">
                <div style="flex: 1;padding: 20px; background-color:#fff;display: flex;flex-direction: column;font-size: 14px;color: #666666;">
                    <span style="display: inline;margin-right: 10px;margin-bottom: 10px">
                        标题:  {{userList.goods.title}}
                    </span>
                    <span>任务数量:  {{userList.nums}}</span>
                </div>

            </div>
            <el-main>
                <el-table :data="fileListArres"  max-height="500">
                    <el-table-column prop="id" label="ID" width="100">
                    </el-table-column>
                    <el-table-column prop="status" label="状态" width="100">
                        <template slot-scope="scope">
                            <div style="display: flex;justify-content: center">
                                <p style="color: red" v-if="scope.row.status == 1">待审核</p>
                                <p style="color: green" v-if="scope.row.status == 3">通过</p>
                                <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-end">
                                    <p style=" cursor:pointer;color: white;display: inline-block;padding: 3px 10px;background-color:#F56C6C; font-size: 12px;border-radius: 12px" v-if="scope.row.status == 2">拒绝</p>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
<!--                    <el-table-column prop="reason" label="拒绝原因" width="200"></el-table-column>-->
                    <el-table-column prop="video" label="视频" width="200">
                        <template slot-scope="scope">
                            <div>
                                <img style="width: 30px;height: 30px;cursor:pointer;" src="../assets/videoTwo.png" @click="videoPupopFn(scope.row.video_src)">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="200">
                        <template slot-scope="scope">
                            <div style="display: flex" v-if="scope.row.status == 2">
                                <el-upload
                                        action="https://gz.zxw233.com/api/common/upload_file"
                                        :show-file-list="false"
                                        :file-list="fileList"
                                        accept=".mp4"
                                        :auto-upload="false"
                                        :on-change="onChangeFn"
                                >
                                    <el-button size="small" type="primary">重新上传</el-button>
                                </el-upload>
                                <el-button class="elBtn" slot="trigger" size="small" type="danger" @click="again(scope.row.id)">保存
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
        </div>
<!--        上传加载-->
        <el-button
                style="opacity: 0;"
                v-loading.fullscreen.lock="fullscreenLoading"
                element-loading-text="拼命上传中"
        >
        </el-button>
<!--        播放视频-->
        <el-dialog
                title="提示"
                :visible.sync="centerDialogVisible"
                center
                class="Videodialog"
                @close="beforeClose"
        >
            <video id="myVideo" style="width: 100%;max-height: 500px;" :src="preFix + videoSrc"  :autoplay="autoplay" ></video>
            <span slot="footer" class="dialog-footer">
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import api from '../lnit/api'
    export default {
        name: "check",
        data() {
            return {
                fullscreenLoading: false,
                autoplay:false,
                preFix:null,
                videoSrc:null,
                centerDialogVisible:false,
                formData: null,
                fileListArr: [],
                fileListArres:[],//详情页列表
                saveVideoList:[],
                fileList: [],
                fromStatus: 1,
                formDate:null,
                i: 1,
                uploadIndex: null,
                saveStatus:false,//上传成功后保存视频
                taskId:null,//任务id
                taskStatus:null,
                videoShow:false,
                userList:null,
            }
        },
        created() {
            this.taskId = this.$route.query.id
            this.taskStatus = this.$route.query.status
            this.taskDetailFn()
            this.preFix = api.url_prefix
        },
        methods: {
            centerDialogVisibleFn(){
              this.centerDialogVisible = false
                myVideo.pause()
              console.log("ss")
            },
            beforeClose(){
                myVideo.pause()
            },
            videoPupopFn(video){//点击播放视频
                this.videoSrc = video
                this.centerDialogVisible = true
                this.autoplay = true
                myVideo.load()
            },
            saveVideoFn(){
                if(!this.saveStatus){
                    return
                }
            },
            taskDetailFn(){
                this.$get({
                    url:api.pk_task_details,
                    data:{
                        id:this.taskId
                    }
                }).then((res)=>{
                    this.fileListArres = res.data.info.videoTasks
                    this.userList = res.data.info
                })
            },
            onChangeFn(file, fileList) {
                this.fileListArr = file
                let size = (file.size/1024/1024).toFixed(1)
                console.log( size)
                if(size > 150){
                    this.$message({
                        message:'请上传不超过150M的视频',
                        type:'error'
                    })
                    return
                }
                this.$forceUpdate()
                this.onProgress()
            },
            onProgress(id) {//上传到服务器
                if (!this.fromStatus) {
                    return
                }

                this.fullscreenLoading = true
                this.videoShow =false
                this.fromStatus = 0
                this.formDate = new FormData()
                // this.formDate.append('file', "12133")
                //let a = this.formDate.get('file')
                this.formDate.set('file', this.fileListArr.raw)
                axios.post( api.url + "/api/common/upload_file", this.formDate, {
                    headers: {
                        "token": localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.saveVideoList = res.data.url
                    this.fromStatus = 1
                    this.videoShow = true
                   this.fullscreenLoading = false
                    this.$message({
                        message:'上传成功请重新保存',
                        type:'success'
                    })
                }).catch(res => {

                })
            },
            again(id) {
                // if(!this.videoShow){
                //     this.$message({
                //         message:'上传中',
                //         type:'success'
                //     })
                //     return
                // }

                if(!this.saveVideoList.length){
                    this.$message({
                        message:'请先上传视频再保存',
                        type:'error'
                    })
                    return
                }

                this.$post({
                    url:api.pk_re_save_videos,
                    data:{
                        task_id:this.taskId,
                        video_src:this.saveVideoList,
                        vid:id,
                    }
                }).then((res)=>{
                    if(res.data.code == 0){
                        this.$message({
                            message:'重新保存成功',
                            type:'success'
                        })
                        this.saveVideoList = []
                        this.taskDetailFn()
                    }
                })
            },
        }
    }
</script>

<style scoped  lang="scss">
    td {
        min-width: 200px;
        text-align: center;
        height: 64px;
        line-height: 64px;
    }

    th {
        min-width: 200px;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }

    td:nth-child(1) {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .loading {
        padding: 20px;

        .loading-title {
            margin-bottom: 35px;
        }
    }

    .el-upload-list .el-upload-list--text {
        border: 2px solid;
    }

    .fileList {
        width: 100%;

        .fileList-tr {
            width: 100%;
        }
    }

    .fileList-btn {
        display: inline-block;
        padding: 0 15px;
        background-color: red;
        font-size: 12px;
        color: white;
        cursor: pointer;
        border-radius: 22px;
        height: 30px;
        line-height: 30px;
        margin: 0;
    }

    .fileList-btns {
        margin-right: 10px;
    }

    .winColor {
        color: blue;
    }

    .elBtn {
        height: 32px;
        margin-left: 10px;
    }
    .el-dialog__body{
        display: flex;
        justify-content: center;
    }
    .cell{
        text-align: center;
    }
</style>
